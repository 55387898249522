<template>
  <div class="container">
    <page-header
      :title="`${artistData.teamName}\n알아보시나요?`"
      :subtitle="'필요한 내용을 남겨주시면 최대한 빨리\n확인해드릴게요!'"
    />

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>이용 용도</h4>
      <div class="ui-border-line"></div>
      <input type="text" v-model="formData.usage" placeholder="EX : 개인 휴식, 워크샵, 가족 행사 등" required>
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>필요 날짜 / 일수</h4>
      <div class="ui-border-line"></div>
      <input type="text" v-model="formData.runday" v-mask="'####.##.## / #박'" placeholder="EX : 2018.12.24 / 2박" required>
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>이용 인원</h4>
      <div class="ui-border-line"></div>
      <input type="text" v-model="formData.personnel" placeholder="EX : 성인 4명, 어린이 2명 등" required>
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>기타 전달사항</h4>
      <div class="ui-border-line"></div>
      <input type="text" v-model="formData.contents" placeholder="추가로 전달할 내용이 있으신가요?" required>
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <!-- <div>
      <h4>제안 내용</h4>
      <div class="ui-border-line"></div>
      <textarea
        ref="textarea"
        v-model="formData.text"
        placeholder="어필할 내용을 입력해주세요."
        minlength="1"
        required
        @focus="e => scrollDown(e)"
      >
      </textarea>
      <div class="ui-border-line"></div>
    </div> -->

    <!-- <div class="ui-border-line ui-h-0 ui-mb-6"></div> -->

    <footer-box
      submitText="예약 문의하기"
      :submitCb="() => postOffer()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'

export default {
  components: {
    PageHeader,
    FooterBox,
  },
  data() {
    return {
      formData: {
        genre: null,
        location: null,
        runday: null,
        usage: null,
        personnel: null,
        contents: null,
      },
      // confirm: false,
      artistData: {},
    }
  },
  computed: {
    validate() {
      let validate = true
      for (let key in this.formData) {
        if (!this.formData[key]) {
          validate = false
          break
        }
      }
      return validate
    },
  },
  beforeMount() {
    const { artistidx } = this.$route.params

    let artistData = sessionStorage.getItem(`artistData:${artistidx}`)

    if (!artistidx || !artistData) {
      this.$router.back()
      return
    }

    this.artistData = JSON.parse(artistData)
    this.formData.genre = this.artistData.genre
    this.formData.location = this.artistData.location
  },
  methods: {
    postOffer() {
      if (!this.validate) return
      // if (!this.confirm) {
      //   this.$store.commit('setModal', {
      //     show: true,
      //     title: '다시 한번 확인하세요!',
      //     body: `${this.formData.price}원으로\n견적을 보내시겠어요?`,
      //     confirm: '보내기',
      //     confirmCb: modal => {
      //       modal.hide()
      //       this.confirm = true
      //       this.postOffer()
      //     },
      //   })
      //   return
      // } else {
      //   this.confirm = false
      // }

      const req = {
        method: 'post',
        url: `/client/${this.$route.params.artistidx}/cusOrder`,
        data: this.formData,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast('문의가 전달되었습니다').push('/home')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  }
}
</script>

<style lang="scss" scoped>
h4 {
  margin: 0 !important;
  font-weight: 500 !important;
  font-size: 2.6rem !important;
  line-height: 6.6rem !important;
}

input, textarea {
  padding: 0;
  width: 100%;
  border: 0;
  font-size: 2rem;
  line-height: 10rem;
}

textarea {
  line-height: 3rem;
  min-height: 14rem;
  padding: 3rem 0;
}

p {
  color: #979797;
  font-size: 2rem;
}
</style>